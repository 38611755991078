import {
  CardCvcElement,
  CardElement,
  CardExpiryElement,
  CardNumberElement,
  Elements,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js'
import {Link, navigate} from 'gatsby'
import React, {useContext, useEffect, useState} from 'react'
import {formatPrice, generateSerial} from '../../components/shared/utils'

import Axios from 'axios'
import {CartContext} from '../../components/gallery/cart/CartProvider'
import CheckoutLayout from '../../components/gallery/checkout/CheckoutLayout'
import LogoAmex from '../../images/svgs/payment/amex.svg'
import LogoMasterCard from '../../images/svgs/payment/mastercard.svg'
import LogoVisa from '../../images/svgs/payment/visa.svg'
import SquareLoader from 'react-spinners/SquareLoader'
import htmlTemplate from '!!raw-loader!../../components/gallery/checkout/receipt.html'
import updateAction from '../../components/gallery/checkout/updateAction'
import {useForm} from 'react-hook-form'
import {useStateMachine} from 'little-state-machine'

const Payment = (props) => {
  const [error, setError] = useState(null)
  const [metadata, setMetadata] = useState(null)
  const [succeeded, setSucceeded] = useState(false)
  const [processing, setProcessing] = useState(false)
  const [data, setData] = useState({})
  // react-hook-form
  const {register, handleSubmit, watch, errors} = useForm()
  // react-stripe-js
  const stripe = useStripe()
  const elements = useElements()
  // cartProvider
  const {cart, reset, count, total} = useContext(CartContext)
  const {state, action} = useStateMachine(updateAction)
  useEffect(() => {
    setData(state.data)
    // console.log(data)
  }, [state.data])

  const onSubmit = async (data) => {
    // render spinner to indicate payment is processing
    setProcessing(true)

    // passing one card element is sufficient.
    const cardElement = elements.getElement(CardNumberElement)
    // pass shipping data to order
    var shipping = {
      address: {
        city: state.data.city,
        country: state.data.country,
        line1: state.data.address1,
        line2: state.data.address2,
        postal_code: state.data.postal_code,
        state: state.data.region,
      },
      name: state.data.firstName + ' ' + state.data.lastName,
    }

    const newCart = cart.map(([sku, quantity]) => {
      let price = formatPrice(sku.price, sku.currency)
      return {price, sku, quantity}
    })
    // // map cart items so they can passed to paymentIntent function
    const items = cart.map(([sku, quantity]) => ({
      type: 'sku',
      parent: sku.id,
      quantity,
    }))
    // CreatePaymentIntent returns promise, pass it - items, details and cardType
    const {
      data: {clientSecret, intentId, shippingCost, totalCost},
    } = await Axios.post('/.netlify/functions/paymentIntent', {
      items,
      shipping,
      cardType: 'card',
    })
    var details = {
      firstName: state.data.firstName,
      lastName: state.data.lastName,
      email: state.data.email,
      serial: generateSerial(),
      totalCost: formatPrice(totalCost, 'gbp'),
      shippingCost: formatPrice(shippingCost, 'gbp'),
    }
    // make the payment using the clientSecret returned from the intent
    const {paymentIntent, error} = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: cardElement,
      },
    })
    if (error) {
      setError(`Payment failed: ${error.message}`)
      // show error stop loader spinner
      setProcessing(false)
      console.log('[error]', error)
    } else {
      const paymentMethodID = paymentIntent.payment_method
      console.log(paymentMethodID)
      const {
        data: {paymentMethod},
      } = await Axios.post('/.netlify/functions/retrievePaymentMethod', {
        paymentMethodID,
      })
      // update SKU.inventory.quantity for purchased items
      await Axios.post('/.netlify/functions/skuUpdate', {
        items,
      })
      // await Axios.post('/.netlify/functions/createContact', {
      // })
      await Axios.post('/.netlify/functions/sendReceipt', {
        details,
        paymentIntent,
        paymentMethod,
        newCart,
        htmlTemplate,
        shippingCost,
      })
      setError(null)
      setSucceeded(true)
      setProcessing(false)
      setMetadata(paymentIntent)
      console.log('[PaymentIntent]', paymentIntent)
      console.log('[PaymentMethod]', paymentMethod)
      console.log(cart)
      // pass order number to template page
      action({details: details})
      action({paymentIntent: paymentIntent})
      action({paymentMethod: paymentMethod})
      action({Cart: cart})
      // reset cart & navigate to successful payment page
      reset()
      navigate('/checkout/success')
    }
  }

  return (
    <CheckoutLayout>
      <div className="flex flex-col  flex-wrap ">
        {/* <div className="border-b border-white"></div> */}
        {/* <div className="flex flex-col"> */}
        <div className="w-full max-w-lg">
          <div className="mt-10 leading-none z-20 text-2xl">
            Personal Details
            <Link className="text-xs hover:underline mx-1 self-center" to="/checkout/details">
              [edit]
            </Link>
          </div>
          <div className="border-b border-white"></div>
          <div className=" my-4 font-medium">
            <div>{data.firstName + ' ' + data.lastName}</div>
            <div>{data.email}</div>
          </div>
        </div>
        <div className="w-full max-w-lg ">
          <div className=" mt-10 leading-none z-20 text-xl md:text-2xl ">
            Shipping Address
            <Link className="text-xs hover:underline mx-1 self-center" to="/checkout/address">
              [edit]
            </Link>
          </div>
          <div className="border-b border-white "></div>
          <div className=" my-4 font-medium ">
            <div>{data.address1}</div>
            <div>{data.address2}</div>
            <div>{data.country}</div>
            <div>{data.region}</div>
            <div>{data.postal_code}</div>
          </div>
        </div>
        {/* </div> */}
        <div className="mt-10 leading-none z-20 text-2xl">Card Details</div>
        <div className="border-b w-full max-w-lg border-white mb-2"></div>
        <div className="flex flex-row my-2">
          <LogoVisa className="w-12 h-12 mr-2" />
          <LogoAmex className="w-12 h-12 mr-2" />
          <LogoMasterCard className="w-12 h-12  mr-2" />
          <div className="italic text-xs self-end"> *accepted cards</div>
        </div>

        <div className="border-b w-full max-w-lg border-white mb-2"></div>
        <form className="w-full max-w-lg" onSubmit={handleSubmit(onSubmit)}>
          <label className="block  tracking-wide text-gray-700 text-xs mb-2">Card Number</label>
          <CardNumberElement className="outline-none bg-white w-full  rounded py-3 px-4 mb-3 leading-tight focus:bg-black focus:text-white" />
          <label className="block  tracking-wide text-gray-700 text-xs mb-2">Exp Date</label>
          <CardExpiryElement className="outline-none bg-white  w-full  rounded py-3 px-4 mb-3 leading-tight focus:bg-black focus:text-white" />
          <label className="block  tracking-wide text-gray-700 text-xs mb-2">CVC</label>
          <CardCvcElement className="outline-none  w-full bg-white   rounded py-3 px-4 mb-3 leading-tight focus:bg-black focus:text-white" />

          {processing ? (
            <div className="my-4">
              <SquareLoader size="30" />
            </div>
          ) : (
            <button
              disabled={!stripe || processing}
              className="text-white bg-gray hover:underline my-4 mb-6 px-4 rounded py-2  ">
              Pay & Submit
            </button>
          )}
        </form>
      </div>
    </CheckoutLayout>
  )
}

export default Payment
